<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="six wide left aligned column">
                <h1 class="ui grey header"> Nouveau Utilisateur </h1>
            </div>
            <div class="ten wide right aligned column">
                <div class="ui primary button" :class="{'disabled': isBusy}" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>

                <div class="ui red button" v-if="isEdit" @click="remove">
                    <i class="times circle icon"></i>
                    Supprimer
                </div>

                <div class="ui button" @click="$router.push({name: 'userList'})">
                    Cancel
                </div>
            </div>
        </div>

        <div class="row" style="background-color: #F6F9F8;" v-show="isEdit">
            <div class="center aligned column">

                <div class="ui circular small image avatar-section">
                    <div class="ui dimmer">
                        <div class="content">
                            <i class="huge camera icon"></i>
                            <h4 style="margin: 5px 0 0 0;"> Changer photo </h4>
                            <input type="file" id="file" accept="image/*" @change="onFileChange">
                        </div>
                    </div>
                    <img :src="avatar_src" alt/>

                    <!--<img src="@/assets/images/avatar.jpg" alt v-else />-->
                </div>

            </div>
        </div>

        <div class="row border-bottom">
            <div class="column">
                <h1 class="ui grey header"> Information generale </h1>
                <div class="ui container">
                    <div class="ui form">
                        <div class="two fields">
                            <div class="field" :class="{'error': $v.new_user.firstname.$error}">
                                <label>Prénom</label>
                                <input type="text" placeholder="Prénom" v-model.trim="$v.new_user.firstname.$model">
                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.new_user.firstname.$error">
                                    <p> <i class="info circle icon"></i> Le Prénom est obligatoire </p>
                                </div>
                            </div>

                            <div class="field" :class="{'error': $v.new_user.lastname.$error}">
                                <label>Nom</label>
                                <input type="text" placeholder="Nom" v-model.trim="$v.new_user.lastname.$model">
                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.new_user.lastname.$error">
                                    <p> <i class="info circle icon"></i> Le Nom est obligatoire </p>
                                </div>
                            </div>

                        </div>
                        <div class="two fields">

                            <div class="field" :class="{'error': $v.new_user.username.$error}">
                                <label>Username</label>
                                <input type="text" placeholder="Username" v-model.trim="$v.new_user.username.$model">
                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.new_user.username.$error">
                                    <p> <i class="info circle icon"></i> Username est obligatoire </p>
                                </div>
                            </div>

                            <div class="field" :class="{'error': $v.new_user.email.$error}">
                                <label>Email</label>
                                <input type="email" placeholder="Email" v-model.trim="$v.new_user.email.$model">
                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.new_user.email.$error">
                                    <p> <i class="info circle icon"></i> Email est invalide</p>
                                </div>
                            </div>

                        </div>

                        <div class="field">
                            <label for="user_type">Type</label>
                            <v-select id="user_type" name="user_type" :options="user_type" style="text-transform: capitalize"
                            v-model.trim="new_user.type"> </v-select>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                <h1 class="ui grey header"> Mot de passe
                    <i v-if="isEdit" class="circle icon show-pass"
                       :class="showPassword ? 'black times' : 'red plus'"
                       @click="showPassword = !showPassword"></i>
                </h1>
                <div class="ui container">
                    <div class="ui form" v-if="showPassword">

                        <div class="field" :class="{'error': $v.password.$error}">
                            <label>Mot de passe</label>
                            <input type="password" placeholder="Mot de passe" v-model.trim="$v.password.$model" autocomplete="new-password">
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.password.$error">
                                <p> <i class="info circle icon"></i> Le Mot de passe est obligatoire </p>
                            </div>
                        </div>

                        <div class="field">
                            <label>Confirmation du Mot de passe</label>
                            <input type="password" placeholder="Confirmation du Mot de passe" v-model.trim="$v.repeat_password.$model">
                             <!-- error message -->
                            <div class="ui negative message" v-if="$v.repeat_password.$error">
                                <p> <i class="info circle icon"></i> Les mots de passe ne correspondent pas. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { required, sameAs, email } from 'vuelidate/lib/validators'
    export default {
        name: "user-crud",
        props:{
            user: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              isBusy: false,
              new_user: {
                  firstname: '',
                  lastname: '',
                  username: '',
                  email: '',
                  type: 'normale'
              },
              password: '',
              repeat_password: '',
              showPassword: true,
              user_type: ['admin', 'manager', 'dispatcher', 'mobile', 'normale'],
              selected_file: null,
              avatar_src: ''
          }
        },
        validations() {
            if(this.showPassword){
                return{
                    new_user: {
                        firstname: {required},
                        lastname: {required},
                        username: {required},
                        email: {email},
                        type: {required},
                    },
                    password: {required},
                    repeat_password: {
                        sameAsPassword: sameAs('password')
                    }
                }
            }
            else{
                return {
                    new_user: {
                        firstname: {required},
                        lastname: {required},
                        username: {required},
                        email: {email},
                        type: {required},
                    },
                }
            }
        },
        methods:{
            save(){
                log('save user:', this.new_user);
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                if(!this.isEdit){ // insert
                    this.new_user.password = this.password;
                    this.$store.dispatchAsync(this.$SHARED.services.user.create, this.new_user).then(data => {
                        this.$notify({
                            group: 'user-message',
                            type: 'success', // warn , error, success, info
                            text: this.$SHARED.messages.user[this.isEdit ? 'update_succeeded' : 'create_succeeded']
                        });
                    }, code => {
                        this.$notify({
                            group: 'user-message',
                            type: 'error', // warn , error, success, info
                            text: this.$SHARED.messages.technical_error
                        });
                    });
                }
                else { // update
                    if(this.showPassword){
                        this.new_user.password = this.password;
                    }
                    this.$store.dispatchAsync(this.$SHARED.services.user.update, this.new_user).then(data => {
                        if(this.selected_file){
                            this.uploadPhoto();
                            this.$router.push({name: 'userList'});
                            return;
                        }
                        this.$router.push({name: 'userList'});
                        this.$notify({
                            group: 'user-message',
                            type: 'success',
                            text: this.$SHARED.messages.user.update_succeeded
                        });
                    }, code => {
                        this.$notify({
                            group: 'user-message',
                            type: 'error', // warn , error, success, info
                            text: this.$SHARED.messages.technical_error
                        });
                    });
                }
            },
            remove(){
                this.$store.dispatchAsync(this.$SHARED.services.user.delete, {_id: this.new_user._id}).then(() => {
                    this.$router.push({name: 'userList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.user.delete_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            },
            uploadPhoto(){
                try {
                    this.isBusy = true;
                    let url =
                        this.$store.state.SERVER_ADDRESS +
                        this.$SHARED.services.user.uploadPhoto;
                    let formData = new FormData($("#form")[0]);
                    let file = document.getElementById("file").files[0];
                    if (!file) {
                        this.isBusy = false;
                        return;
                    }
                    formData.append("document", file);
                    formData.append("user_id", this.new_user._id);
                    $.ajax({
                        url: url,
                        type: "POST",
                        data: formData,
                        xhrFields: {
                            withCredentials: true
                        },
                        async: true,
                        cache: false,
                        contentType: false,
                        enctype: "multipart/form-data",
                        processData: false
                    })
                        .done(resp => {
                            this.isBusy = false;
                            let type = "error";
                            let text = this.$SHARED.messages[resp.message];
                            if (resp.succeeded) {
                                type = "success";
                                text = this.$SHARED.messages.user.update_succeeded;
                            }
                            this.$notify({
                                group: "user-message",
                                type,
                                title: "",
                                text: text
                            });
                        })
                        .fail(err => {
                            this.isBusy = false;
                            this.$notify({
                                group: "user-message",
                                type: "error",
                                title: "Alert",
                                text:
                                    "erreur d'importation veuillez vérifier votre fichier"
                            });
                        });
                } catch (e) {
                    console.error(e, e);
                }
            },
            onFileChange(event){
                const file = event.target.files[0];
                if(file.type.split('/')[0] !== 'image'){
                    document.getElementById("file").value='';
                    this.$notify({group: "user-message", type: "error", title: "Alert",
                        text: "Merci de choisir une format Image!"
                    });
                    return;
                }
                this.selected_file = file.name
                const reader = new FileReader();
                const context = this;
                reader.onload = function (e) {
                    context.avatar_src = e.target.result;
                }
                reader.readAsDataURL(file);
            },
        },
        computed:{
            current_user(){
                return this.$store.state.current_user;
            },
            server_address(){
                return this.$store.state.SERVER_ADDRESS;
            }
        },
        mounted() {
            if(this.user.hasOwnProperty('_id')){
                this.isEdit = true;
                this.new_user = JSON.parse(JSON.stringify(this.user));
                this.showPassword = false;
                this.avatar_src = this.new_user.user_image
                        && this.new_user.user_image !== ''
                        ? this.server_address + "/" + this.new_user.user_image
                        : require('@/assets/images/avatar.jpg') ;
            }
            $('.avatar-section').dimmer({on: 'hover'});
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
    .show-pass{
        cursor: pointer;
        font-size: 22px !important;
        display: inline !important;
    }
    #file{
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .avatar-section{
        position: relative;
        cursor: pointer;
    }

</style>